import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseAPIService } from '../../Helper/base-api.service';
import { IndexModel, ApiResponse, Dictionary } from '../../Helper/common-model';

@Injectable({
  providedIn: 'root'
})
export class LookupService {

  constructor(private readonly _baseService: BaseAPIService) { }
  GetList(model: IndexModel): Observable<ApiResponse<LookupMasterModel[]>> {
    let url = `${this._baseService.API_Url.LookupMasterList_Api}`;
    return this._baseService.post(url, model);
  }

  GetLookupMaster(id: string): Observable<ApiResponse<LookupMasterModel>> {
    let url = `${this._baseService.API_Url.LookupMasterDetail_Api}${id}`;
    return this._baseService.get(url);
  }

  AddUpdateLookupMaster(model: LookupMasterPostModel): Observable<ApiResponse<string>> {
    let url = `${this._baseService.API_Url.LookupMasterAddUpdate_Api}`;
    return this._baseService.post(url, model);
  }
  ChangeLookupMasterActiveStatus(id: string, status?: string): Observable<ApiResponse<LookupMasterModel[]>> {
    let url = `${this._baseService.API_Url.LookupMasterChangeActiveStatus_Api}${id}`;
    return this._baseService.get(url);
  }
  DeleteLookupMaster(id: string): Observable<ApiResponse<LookupMasterModel[]>> {
    let url = `${this._baseService.API_Url.LookupMasterDelete_Api}${id}`;
    return this._baseService.get(url);
  }

  CheckURLDuplicate(id: string,value : string, isBtoB :boolean): Observable<ApiResponse<string>> {
    let url = `${this._baseService.API_Url.LookupMasterCheckURLDuplicateApi}${id}`;

    let param = new Dictionary<any>();
    param.Add("id", id)
    param.Add("value", value)
    param.Add("isBtoB", isBtoB)
    return this._baseService.get(url);
  }

}

export interface LookupMasterPostModel {
  Id: string;
  Name: string;
  Value: string;
  ImagePath: string;
  SortedOrder: number;
  LookUpType: string;
  Info: string;
  MetaTitle: string;
  MetaDescription: string;
  Url: string;
  BtoBInfo: string;
  BtoBMetaTitle: string;
  BtoBMetaDescription: string;
  BtoBUrl: string;
  Keyword : string;
}

export interface LookupMasterModel {

  Id: string;
  Name: string;
  Value: string;
  ImagePath: string;
  SortedOrder: number ;
  LookUpType: string ;
  LookUpTypeName: string;
  IsSubLookup: boolean;
  Info: string;
  MetaTitle: string;
  MetaDescription: string;
  Url: string;
  BtoBInfo: string;
  BtoBMetaTitle: string;
  BtoBMetaDescription: string;
  BtoBUrl: string;
  Keyword : string;
  CreatedBy: number;
  CreatedOn: string;
  ModifiedBy: number;
  ModifiedOn: string;
  IsActive: boolean ;

}










