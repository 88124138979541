<!-- Page Header Start-->
<!-- <div class="page-new-header">
  <div class="page-new-header-title">
    {{pageName | titlecase}}
  </div>
  <div class="page-new-header-action">
    <button routerLink="{{model.Id.length > 0 ?'../../' :'../'}}" class="page-new-header-action-items"
      data-title="Back to list"> <i class="fa-light fa-arrow-left"></i> </button>
  </div>
</div> -->
<!-- Page Header End-->

<div class="content-box">
  <form [formGroup]="formgrp" (ngSubmit)="onSubmit()">
    <div class="row">
      <!-- Done -->
      <div class="col-md-3 mb-3">
        <label>Title</label>
        <input type="text" class="form-control" [(ngModel)]="model.Title" formControlName="Title"
          [ngClass]="{ 'is-invalid': f.Title.errors && f.Title.touched}" placeholder="Title">
        <div class="invalid-feedback"
          *ngIf="(formgrp.get('Title')?.hasError('required') && formgrp.get('Title')?.touched)">
          Please enter <strong>Title</strong>...!</div>
      </div>
      <!-- <div class="col-md-4 mb-3">
        <label>Category</label>
        <ng-select [items]="this.dropDown!.ddlGeneralEntryCategory" formControlName="Category" [searchable]="true"
          bindLabel="Text" bindValue="Value" placeholder="Category"
          [ngClass]="{ 'is-invalid': f.Category.errors && f.Category.touched}" [(ngModel)]="model.CategoryId">
        </ng-select>
        <div class="invalid-feedback"
          *ngIf="(formgrp.get('Category')?.hasError('required') && formgrp.get('Category')?.touched)">
          Please select <strong>Category</strong>...!</div>

      </div> -->

      <div class="col-md-3 mb-3">
        <label>Sort Order (1-999999)</label>

        <input type="number" [(ngModel)]="model.SortedOrder" (keypress)="_commonService.NumberOnly($event)"
          [minlength]="1" [maxLength]="6" formControlName="SortedOrder"
          [ngClass]="{ 'is-invalid': f.SortedOrder.errors && f.SortedOrder.touched}" class="form-control" id="input4"
          placeholder="Sort Order">
        <div class="invalid-feedback"
          *ngIf="(formgrp.get('SortedOrder')?.hasError('required') && formgrp.get('SortedOrder')?.touched)">
          Enter Sort Order </div>
        <div class="invalid-feedback"
          *ngIf="!formgrp.get('SortedOrder')?.hasError('required') && ((formgrp.get('SortedOrder')?.hasError('maxlength') || formgrp.get('SortedOrder')?.hasError('minlength')) && formgrp.get('SortedOrder')?.touched) || (formgrp.get('SortedOrder')?.hasError('maxlength') || formgrp.get('SortedOrder')?.hasError('minlength'))">
          Sort Order length must be 1-6 digit. </div>


      </div>
      <div *ngIf="selectedCategory?.IsShowThumbnail" class="col-md-6 mb-3">
        <label> Image</label>
        <input type="hidden" name="ImagePath" [(ngModel)]="model.ImagePath" formControlName="ImagePath">
        <app-file-selector [MaxFileLength]="1" (FilesChanged)="onDocumentAttach($event)"
          [CurrentFileLength]="model!.ImagePath ? 1: 0" [FileFilter]="'.jpeg,.gif,.png,.jpg,.webp'"
          [ngClass]="{ 'is-invalid': f.ImagePath.errors && f.ImagePath.touched}">
        </app-file-selector>
        <div class="invalid-feedback"
          *ngIf="(formgrp.get('ImagePath')?.hasError('required') && formgrp.get('ImagePath')?.touched)">
          Please upload image...!</div>

        <span *ngIf="model.ImagePath && !isFileAttached  && model.Id.length>0">

          <a href="javascript:void()" class="small-media-items position-relative mb-3">
            <div class="small-media-items-img"> <img [src]="model.ImagePath" ViewImage
                [defaultSrc]="'assets/images/300x300.jpg'" /> </div>
            <div class="small-media-items-action">
              <a href="javascript:void()" (click)="RemoveDocument(model.ImagePath)" title="Delete"><i
                  class="fa-light fa-trash-can"></i></a>
              <a [href]="model.ImagePath" target="_blank">
                <i data-bs-toggle="tooltip" data-placement="bottom" title="View" class="fa-light fa-eye"></i>
              </a>
            </div>
          </a>

        </span>

      </div>

      <div class="col-md-6 mb-0"
        *ngIf="selectedCategory?.ContentType==(contentTypeEnum.Photo).toString() || selectedCategory?.ContentType==(contentTypeEnum.MultipleImages).toString() ||selectedCategory?.ContentType==(contentTypeEnum.Document).toString() || selectedCategory?.ContentType==(contentTypeEnum.Video).toString()">
        <label>Attachments <span class="label-info"> (Preferred size: 500*500 ) </span></label>
        <div class="dropzone dropzone-light upload-btn-wrapper w-100">
          <input type="hidden" name="DataItems" value="{{this.model!.Data?.length==0 ? dataItems: this.model.Data}}"
            formControlName="DataItems">
          <app-file-selector [MaxFileLength]="selectedCategory?.ContentType==(contentTypeEnum.Photo).toString() ?1:5"
            (FilesChanged)="onFileAttach($event)"
            [CurrentFileLength]="(this.dataItems.length +(this.model.Data?.length??0))" [FileFilter]="acceptedFiles"
            [ngClass]="{ 'is-invalid': f.DataItems.errors && f.DataItems.touched}">
          </app-file-selector>
          <div class="invalid-feedback"
            *ngIf="(formgrp.get('DataItems')?.hasError('required') && formgrp.get('DataItems')?.touched)">
            Please Add File...!</div>
        </div>
        <div class="d-flex flex-wrap align-items-center mt-1 upload-content-list" *ngIf="dataItems">
          <ng-container *ngFor="let itm of dataItems">
            <span [ngSwitch]="getFileType(itm.Value)">

              <a *ngSwitchCase="'image'" href="javascript:void()" class="small-media-items position-relative mb-3">
                <div class="small-media-items-img"> <img [src]="itm.Value" ViewImage
                    [defaultSrc]="'assets/images/300x300.jpg'" /> </div>
                <div class="small-media-items-action">
                  <a href="javascript:void()" (click)="deleteItems(itm.Id)" title="Delete"><i
                      class="fa-light fa-trash-can"></i></a>
                  <a [href]="itm.Value" target="_blank">
                    <i data-bs-toggle="tooltip" data-placement="bottom" title="View" class="fa-light fa-eye"></i>
                  </a>
                </div>
              </a>
              <a *ngSwitchCase="'doc'" href="javascript:void()"
                class="small-media-items small-media-items-pdf text-danger position-relative mb-3">
                <div class="small-media-items-img"><i class="bi bi-file-earmark-pdf"></i></div>
                <div class="small-media-items-action">
                  <a href="javascript:void()" (click)="deleteItems(itm.Id)" title="Delete"><i
                      class="fa-light fa-trash-can"></i></a>
                  <a [href]="itm.Value" target="_blank">
                    <i data-bs-toggle="tooltip" data-placement="bottom" title="View" class="fa-light fa-eye"></i>
                  </a>
                </div>
              </a>


              <a *ngSwitchCase="'video'" href="javascript:void()"
                class="small-media-items small-media-items-video position-relative mb-3">
                <div class="small-media-items-img"> <i class="bi bi-camera-video"></i> </div>
                <div class="small-media-items-action">
                  <a href="javascript:void()" (click)="deleteItems(itm.Id)" title="Delete"><i
                      class="fa-light fa-trash-can"></i></a>
                  <a [href]="itm.Value" target="_blank">
                    <i data-bs-toggle="tooltip" data-placement="bottom" title="View" class="fa-light fa-eye"></i>
                  </a>
                </div>
              </a>

            </span>
          </ng-container>

        </div>
      </div>

      <div class="col-md-6 mb-3" *ngIf="selectedCategory?.IsShowUrl">
        <label>Link </label>
        <input type="text" class="form-control" [(ngModel)]="model.Url" formControlName="Url"
          [ngClass]="{ 'is-invalid': f.Url.errors && f.Url.touched}" placeholder="Link">
        <div class="invalid-feedback" *ngIf="(formgrp.get('Url')?.hasError('required') && formgrp.get('Url')?.touched)">
          Please enter <strong>Link</strong>...!</div>
      </div>

      <div class="col-md-12 mb-3">
        <label>Description</label>
        <angular-editor [(ngModel)]="model.Description" formControlName="Description" [config]="editorConfig"
          placeholder="Description" [ngClass]="{ 'is-invalid': f.Description.errors && f.Description.touched}"
          id="Description"></angular-editor>


        <div class="invalid-feedback"
          *ngIf="(formgrp.get('Description')?.hasError('required') && formgrp.get('Description')?.touched)">
          Please enter Description</div>
      </div>
      <div class="col-md-12 mb-3">
        <label>Keyword</label>
        <textarea [(ngModel)]="model.Keyword" formControlName="Keyword" placeholder="Keyword" class="form-control"
          [ngClass]="{ 'is-invalid': f.Keyword!.errors && f.Keyword!.touched}" id="Keyword"></textarea>
        <div class="invalid-feedback"
          *ngIf="(formgrp.get('Keyword')?.hasError('required') && formgrp.get('Keyword')?.touched)">
          Please enter Keyword</div>
      </div>
    </div>

    <div class="form-footer-btn-grp widget-content widget-content pt-4 text-center">
      <button type="submit" class="btn btn-default me-3"> <i class="fas fa-check me-1"></i>
        Save</button>
      <button type="button" (click)="onClose()" class="btn btn-default btn-border"> <i class="fas fa-times me-1"></i>
        Cancel</button>
    </div>
  </form>
</div>
