import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-general-entry-sub-category-master',
  templateUrl: './general-entry-sub-category-master.component.html',
  styleUrls: ['./general-entry-sub-category-master.component.scss']
})
export class GeneralEntrySubCategoryMasterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
