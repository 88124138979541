import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
;
@Component({
  selector: 'app-dashboard',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  @ViewChild('pageBody', { static: false }) appPageBody!: ElementRef;

  constructor(private renderer: Renderer2, private readonly _route: Router, @Inject(DOCUMENT) private document: Document) { }

  ngOnInit(): void {
    setTimeout(() => {

      let theme: string = localStorage.getItem('currentTheme') != null ? localStorage.getItem('currentTheme') as string : 'theme-default' as string;
      this.setTheme(theme);
    }, 100);
  }
  setTheme(cssClass: string) {
    if (cssClass == '') {
      var data =
        localStorage.getItem('currentTheme') != null ? localStorage.getItem('currentTheme') : 'theme-default';
      if (data) {
        cssClass = data;
      }
    } else {
      localStorage.setItem('currentTheme', cssClass);
    }


    let el = this.appPageBody.nativeElement;

    let classes = this.document.body.classList;
    // el.className.split(' ');  //old concept
    let regex = /^b\d$/;
    classes.forEach((cl: string) => {
      if (cl.length > 0) {
        //   this.renderer.removeClass(el, cl); //old concept
        this.document.body.classList.remove(cl)
      }
    });

    if (cssClass != undefined) {
      this.document.body.classList.add(cssClass);
      //this.renderer.addClass(el, cssClass);  //old concept
    } else {
      this.renderer.addClass(el, 'theme-default');
      this.document.body.classList.add('theme-default');

    }
  }

}
