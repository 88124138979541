<!-- Page Header Start-->
<!-- <div class="page-new-header">
  <div class="page-new-header-title">
    General Entry Category Detail
  </div>
  <div class="page-new-header-action">
    <button *ngIf="!this.model?.IsSystemEntry" routerLink="../../edit/{{recordId}}" class="page-new-header-action-items"
      data-title="Edit"> <i class="fa-light fa-pen"></i></button>
    <button routerLink="/admin/master/general-entry-category" class="page-new-header-action-items"
      data-title="Back to list"> <i class="fa-light fa-arrow-left"></i> </button>
  </div>
</div> -->
<!-- Page Header End-->


<div class="content-box">

  <div class="row">
    <div class="col-md-4">
      <div class="single-detail-img">
        <img [src]="this.model.ImagePath" ViewImage [defaultSrc]="'assets/images/no-img.svg'">
      </div>
    </div>
    <div class="col-md-8 mt-4 mt-md-0">
      <div class="single-detail-info">
        <h2>{{this.model.Name}}</h2>
        <table class="table table-borderless table-data-info table-font-15 table-v-align-top m-0">
          <tbody>
            <tr>
              <td width="250px"><strong>Sort Order: </strong> </td>
              <td>{{this.model.SortedOrder}}</td>
            </tr>
            <tr>
              <td><strong>Content Type : </strong> </td>
              <td>{{this.model.ContentTypeText}}</td>
            </tr>
            <tr>
              <td><strong> Is Show In Main: </strong> </td>
              <td>{{this.model.IsShowInMain?'Yes':'No'}}</td>
            </tr>
            <tr>
              <td><strong> Is Show Data In Main: </strong> </td>
              <td>{{this.model.IsShowDataInMain?'Yes':'No'}}</td>
            </tr>
            <tr>
              <td><strong> Is Single Entry: </strong> </td>
              <td>{{this.model.IsSingleEntry? 'Yes': 'No'}}</td>
            </tr>
            <tr>
              <td><strong> Is System Entry: </strong> </td>
              <td>{{this.model.IsSystemEntry? 'Yes': 'No'}}</td>
            </tr>
            <tr>
              <td><strong>Show Thumbnail: </strong> </td>
              <td>{{this.model.IsShowThumbnail? 'Yes': 'No'}}</td>
            </tr>
            <tr>
              <td><strong>Show URL Field: </strong> </td>
              <td>{{this.model.IsShowUrl? 'Yes': 'No'}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
