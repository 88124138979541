<!--  BEGIN CONTENT AREA  -->

    <div class="row m-0">
        <div class="col-xl-12 col-lg-12 col-md-12">

            <div class="page-not-found text-center">
                <div class="page-not-found-img"> <img src="assets/images/not-found.png" alt=""></div>
                <h1>Page Not Found
                </h1>
                <p>The page you are looking for might have been moved , <br/> renamed or might never existed
                </p>
                <a href="javascript:void(0)" class="btn btn-primary mt-3">Go Back</a>
            </div>

        </div>
    </div>


<!--  END CONTENT AREA  -->
