<!-- Default HTML Start -->
<section class="default-section py-5">
  <div class="row">
    <div class="col-lg-12 text-center">
      <div class="default-section-img">
        <img src="assets/images/display-desktop-mobile.png" alt="">
      </div>
      <div class="default-section-info mt-5">
        <h1 *ngIf="data.subject">{{data.subject}}</h1>
        <p *ngIf="data.Description">{{data.Description}}
        </p>
        <ng-container *ngIf="data.urlLabel">
          <ng-container *ngIf="data.url; else elseTemplate">
            <button [routerLink]="data.url" class="btn btn-dark"> <i class="fa-regular fa-plus"></i>
              {{data.urlLabel}}</button>
          </ng-container>
          <ng-template #elseTemplate>
            <button *ngIf="data.urlLabel" (click)="onButtonClick()" class="btn btn-dark"> <i
                class="fa-regular fa-plus"></i>
              {{data.urlLabel}}</button>
          </ng-template>


        </ng-container>
      </div>
    </div>
  </div>
</section>
<!-- Default HTML End -->
