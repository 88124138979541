import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lookup-type-add-edit',
  templateUrl: './lookup-type-add-edit.component.html',
  styleUrls: ['./lookup-type-add-edit.component.scss']
})
export class LookupTypeAddEditComponent implements OnInit {
 constructor() { }
 ngOnInit(): void {
  }
}
