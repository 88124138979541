<button type="button" #ShowPopupBtn class="d-none fieldset-header-btn" data-bs-toggle="modal"
  data-bs-target="#EDocketPopup">
</button>
<div class="custom-modal e-docket-modal modal fade" data-bs-backdrop="static" data-bs-keyboard="false" id="EDocketPopup"
  #EDocketPopup>
  <div class="modal-dialog modal-dialog-centered  modal-md" role="document">
    <div class="modal-content">
      <div class="modal-header ">
        <h5 class="modal-title m-0">Import Excel</h5>
        <button type="button" #hidePopupBtn class="modal-close" (click)="onCancelClick()" data-bs-dismiss="modal"
          aria-label="Close">
          <i class="fa-light fa-xmark"></i>
        </button>
      </div>
      <div class="modal-body">

        <div class="row">

          <div class="col-lg-12" *ngIf="IsShowEDocket">
            <label>Select Excel </label>
            <app-file-selector [MaxFileLength]="1" (FilesChanged)="onDocumentAttach($event)"
              [CurrentFileLength]="file ? 1: 0" [FileFilter]="FileAccept">
            </app-file-selector>
          </div>

        </div>
        <div class="modal-footer border-0 w-100 p-0 justify-content-between">
          <a href="javascript:void(0)" (click)="onDownloadFile()" class="text-dark">
            <i class="fa-light fa-download me-1"></i> Download Default Template</a>
          <!-- href="{{FileURL}}" target="_blank" -->

          <button type="button" [disabled]="!file"  (click)="onSubmitClick()" class="btn btn-default ms-auto"
            data-bs-dismiss="modal"><i class="fas fa-check me-1"></i> Submit</button>
        </div>

      </div>
    </div>
  </div>
</div>
