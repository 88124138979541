import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DropDownModel } from 'src/app/Shared/Helper/common-model';
import { DropDown_key, EditorConfig } from 'src/app/Shared/Helper/constants';
import { LookupMasterPostModel, LookupService, LookupMasterModel } from '../../../../../../Shared/Services/Master/lookup.service';
import { CommonService } from '../../../../../../Shared/Services/common.service';
import { ToastrService } from 'ngx-toastr';
import { FileInfo } from 'src/app/Shared/Helper/shared/file-selector/file-selector.component';
import { LookupTypeMasterModel, LookupTypeService } from 'src/app/Shared/Services/Master/lookup-type.service';

@Component({
  selector: 'app-lookups-add-edit',
  templateUrl: './lookups-add-edit.component.html',
  styleUrls: ['./lookups-add-edit.component.scss']
})
export class LookupsAddEditComponent implements OnInit {
  dropDown = new DropDownModel();
  model = {} as LookupMasterPostModel;
  isFileAttached = false;
  formgrp = this.fb.group({
    Name: [undefined, Validators.required],
    SortedOrder: [undefined, Validators.required],
    ImagePath: [undefined],
    Value: [undefined],
    Info: [undefined],
    MetaTitle: [undefined],
    MetaDescription: [undefined],
    Url: [undefined],
    BtoBInfo: [undefined],
    BtoBMetaTitle: [undefined],
    BtoBMetaDescription: [undefined],
    BtoBUrl: [undefined],
    Keyword: [undefined],
  });

  editorConfig = EditorConfig.Config;
  get ddlkeys() { return DropDown_key };
  get f() { return this.formgrp.controls; }
  @Output() OnSave = new EventEmitter<{ status: boolean, recordId: string }>();
  @Output() OnClose = new EventEmitter<boolean>();
  _typeData = {} as LookupTypeMasterModel;
  @Input('typeData') set typeData(value: LookupTypeMasterModel) {
    this._typeData = value;
    if (this._typeData) {
      this.setLookupTypeConfig();
    }
  }
  ViewMode = 'Detail'
  @Input('mode') set mode(value: string) {
    this.ViewMode = value;
    for (let item in this.formgrp.controls) {
      if (this.ViewMode == 'Detail') {

        this.formgrp.controls[item].disable()
      }
      else {
        this.formgrp.controls[item].enable()

      }
      this.formgrp.controls[item].updateValueAndValidity();
    }


  }
  @Input('Id') set Id(value: string) {

    this.reset();
    this.model.Id = value;

    if (this.model !== null && this.model.Id?.length > 0) {
      this.getDetail();
    }
  }

  get getFileName() { return this.model.ImagePath ? this.model.ImagePath.split('/')[this.model.ImagePath.split('/').length - 1] : '' }
  constructor(private readonly _lookupService: LookupService, private readonly _lookupTypeService: LookupTypeService,
    private readonly fb: FormBuilder, public _commonService: CommonService, private readonly toast: ToastrService) {

  }

  setLookupTypeConfig() {
    if (!this._typeData) {
      return;
    }
    if (!this._typeData.IsImage) {

      // this.formgrp.get('ImagePath')?.clearValidators();
      // this.formgrp.get('ImagePath')?.updateValueAndValidity();

    } else {

      // this.formgrp.get('ImagePath')?.setValidators([Validators.required]);
      // this.formgrp.get('ImagePath')?.updateValueAndValidity();
    }
    if (!this._typeData.IsValue) {

      this.formgrp.get('Value')?.clearValidators();
      this.formgrp.get('Value')?.updateValueAndValidity();

    } else {

      this.formgrp.get('Value')?.setValidators([Validators.required]);
      this.formgrp.get('Value')?.updateValueAndValidity();
    }
  }
  ngOnInit(): void {
    if (this.Id?.length > 0) {
      this.getDetail();
    }
  }

  onClose(val = true) {

    this.OnClose.emit(val);

  }
  onSubmit() {

    this.formgrp.markAllAsTouched();
    if (this.formgrp.valid) {

      this.model.SortedOrder = Number(this.model.SortedOrder);

      this._lookupService.AddUpdateLookupMaster(this.model).subscribe(x => {
        if (x.IsSuccess) {
          this.toast.success(x.Message as string);
          this.onClose(true);
        } else {
          this.toast.error(x.Message as string);
          this.onClose(false);
        }


      }, error => { });

    }
  }

  RemoveDocument(file: string) {
    this.model.ImagePath = '';
  }
  getDetail() {

    this._lookupService.GetLookupMaster(this.model.Id).subscribe(x => {
      if (x.IsSuccess) {
        const resData = x.Data as LookupMasterModel;
        this.model = x.Data as any;
        this.model = {
          Id: resData.Id,
          Name: resData.Name,
          ImagePath: resData.ImagePath,
          Value: resData.Value,
          SortedOrder: Number(resData.SortedOrder),
          LookUpType: resData.LookUpType,
          Info: resData.Info,
          MetaTitle: resData.MetaTitle,
          MetaDescription: resData.MetaDescription,
          Url: resData.Url,
          BtoBInfo: resData.BtoBInfo,
          BtoBMetaTitle: resData.BtoBMetaTitle,
          BtoBMetaDescription: resData.BtoBMetaDescription,
          BtoBUrl: resData.BtoBUrl,
          Keyword: resData.Keyword,
          ...x.Data
        } as LookupMasterPostModel;

        this.isFileAttached = this.model.ImagePath ? false : this.isFileAttached;
      }
    })
  }
  onDocumentAttach(file: FileInfo[]) {
    this.model.ImagePath = file[0].FileBase64;
    this.isFileAttached = true;
  }
  reset() {
    this.model = {} as LookupMasterPostModel;
    this.isFileAttached = false;
    this.formgrp.reset();
    this.setLookupTypeConfig()
  }

}
