import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';
import { CommonService } from 'src/app/Shared/Services/common.service';
import { DashboardService, DashboardViewModel } from 'src/app/Shared/Services/dashboard.service';
import { ProductService } from 'src/app/Shared/Services/product.service';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss']
})
export class AdminDashboardComponent implements OnInit {
  model = {} as DashboardViewModel;
  constructor(private _router: Router, private _activatedRoute: ActivatedRoute, private readonly _commonService: CommonService,
    private readonly toast: ToastrService, private _dashboardService: DashboardService, private http: HttpClient) {

  }
  ngOnInit(): void {
    this.getCount()
  }

  getCount(): void {

    this._dashboardService.GetCountDetail().pipe(take(1)).subscribe(response => {
      if (response.IsSuccess) {
        this.model = response.Data as DashboardViewModel;
        this._dashboardService.dashboardCount = this.model;
      }
    });
  }

}
