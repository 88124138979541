<header>
  <div class="header-l-side d-flex align-items-center flex-auto ">
    <a href="javascript:void(0);" routerLink="/admin" class="app-logo d-block">
      <img src="assets/images/client/logo.png" alt="" />
    </a>
    <a href="javascript:void(0);" class="sidebar-menu-btn"><i class="fa-light fa-bars-sort"></i></a>
  </div>
  <div class="header-r-side d-flex align-items-center justify-content-end flex-auto">
    <!-- <div class="theme-change-btn-grp d-none d-lg-flex align-items-center mr-2">
      <a href="javascript: void(0);" class="btn-theme-default" (click)="changeTheme('theme-default')"></a>
      <a href="javascript: void(0);" class="btn-theme-dark" (click)="changeTheme('theme-dark')"></a>
      <a href="javascript: void(0);" class="btn-theme-primary" (click)="changeTheme('theme-primary')"></a>
      <a href="javascript: void(0);" class="btn-theme-green" (click)="changeTheme('theme-green')"></a>
    </div> -->
    <div class="header-r-side-btn">
      <ul>
        <li> <a href="javascript:void(0)" data-title="{{themeTitle}}" (click)="changeTheme('dark-theme','Dark mode')" class="btn-theme-mode"><i> &nbsp;</i></a> </li>
        <li> <a href="javascript:void(0)"><i class="fa-light fa-bells"></i></a> </li>
        <li class="dropdown login-username"> <a href="javascript:void(0)" class="d-flex" data-bs-toggle="dropdown" aria-expanded="false">
            <div class="login-username-img">
              <img [src]="_authService.GetUserDetail()?.ProfilePhoto" ViewImage [defaultSrc]="'assets/images/avatar.png'"
              alt="" />
            </div>
            <span class="login-name"> {{this._authService!.GetUserDetail()?.FullName }} <i class="fa-light fa-angle-down"></i> </span>
          </a>
          <ul class="header-dropdown dropdown-menu">
            <div class="header-dropdown-head">
              <div class="header-dropdown-head-img">
                <img [src]="_authService.GetUserDetail()?.ProfilePhoto" ViewImage [defaultSrc]="'assets/images/avatar.png'"
                alt="" />
              </div>
              <div class="header-dropdown-head-info">
                <h4>{{this._authService!.GetUserDetail()?.FullName }}</h4>
                <p>Admin</p>
              </div>
            </div>
            <div class="header-dropdown-body">
              <div class="theme-change-btn-grp d-flex align-items-center justify-content-center mb-2">
                <a href="javascript: void(0);" class="btn-theme-default" (click)="changeTheme('theme-default')"></a>
                <a href="javascript: void(0);" class="btn-theme-dark" (click)="changeTheme('theme-dark')"></a>
                <a href="javascript: void(0);" class="btn-theme-primary" (click)="changeTheme('theme-primary')"></a>
                <a href="javascript: void(0);" class="btn-theme-green" (click)="changeTheme('theme-green')"></a>
              </div>
              <ul>
                <li> <a href="javascript:void(0)"><i class="fa-regular fa-user-pen"></i> Edit Profile</a> </li>
                <li> <a href="javascript:void(0)"><i class="fa-regular fa-gear"></i> Setting</a> </li>
                <li> <a href="javascript:void(0)"><i class="fa-regular fa-circle-info"></i> Help & Support</a> </li>
              </ul>
            </div>
            <div class="header-dropdown-footer text-center">
              <a (click)="this._authService.LogOut()" href="javascript:void(0)" class="btn btn-default"><i class="fa-solid fa-arrow-right-from-bracket me-1"></i> Logout</a>
            </div>
        </ul>
        </li>
      </ul>
    </div>
  </div>
</header>

<app-navigation></app-navigation>
