<!-- Page Header Start-->
<div class="page-new-header">
    <div class="page-new-header-title">
        Media Gallery
    </div>
    <div class="page-new-header-action">
        <button routerLink="" class="page-new-header-action-items" data-title="Add New Blog"> <i
                class="fa-regular fa-plus"></i></button>
        <div class="page-new-header-action-search">
            <button type="button" class="btn-filter-search"><i class="fa-light fa-magnifying-glass"></i></button>
            <input type="search" class="form-control" placeholder="Search..." />
        </div>
    </div>
</div>
<!-- Page Header End-->

<div class="tab-btns text-center mb-3 mb-md-4">
    <a href="javascript:void(0)" class="tab-btns-items active"><i class="fa-regular fa-images"></i> Banner Slider</a>
    <a href="javascript:void(0)" class="tab-btns-items"><i class="fa-regular fa-newspaper"></i> Home
        Advertisement</a>
    <a href="javascript:void(0)" class="tab-btns-items"><i class="fa-light fa-album-collection"></i> Collections</a>
</div>

<div class="page-banner-slide">
    <div class="row page-form-filter">
        <div class="col-md-2 col-xxl-1 mb-3">
            <label class="d-block mb-1">Order <span class="label-info"> (1-999999) </span></label>
            <input type="number" formcontrolname="SortedOrder" id="input4" placeholder="1" class="form-control"
                maxlength="6" minlength="1">
        </div>
        <div class="col-md-5 col-xxl-3 mb-3">
            <label class="d-block mb-1">Desktop <span class="label-info"> (2000*600 ) </span></label>
            <div class="dropzone dropzone-light upload-btn-wrapper w-100">
                <input type="hidden" name="productFile" formControlName="productFile">
                <app-file-selector [MaxFileLength]="10" [FileFilter]="'.jpeg,.gif,.png,.jpg,.webp'">
                </app-file-selector>
            </div>
        </div>
        <div class="col-md-5 col-xxl-3 mb-3">
            <label class="d-block mb-1">Mobile <span class="label-info"> (750*550 ) </span></label>
            <div class="dropzone dropzone-light upload-btn-wrapper w-100">
                <input type="hidden" name="productFile" formControlName="productFile">
                <app-file-selector [MaxFileLength]="10" [FileFilter]="'.jpeg,.gif,.png,.jpg,.webp'">
                </app-file-selector>
            </div>
        </div>
        <div class="col-md-4 col-xxl-3 mb-3">
            <label class="d-block mb-1">Link </label>
            <input type="text" placeholder="Enter Redirect URL" class="form-control">
        </div>
        <div class="col-md-4 col-xxl-2 mb-3">
            <label class="d-block mb-1"> &nbsp;</label>
            <button type="submit" class="btn btn-default">Submit </button>
        </div>
    </div>
</div>

<div class="table-responsive table-shadow">
    <table class="custom-mat-table media-table">
        <thead>
            <tr>
                <th>Order </th>
                <th>Desktop </th>
                <th>Mobile </th>
                <th>Link </th>
                <th>Status </th>
                <th style="min-width: 70px;">Action </th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>1</td>
                <td>
                    <div class="table-list-img d-flex align-items-center justify-content-center">
                        <a href="https://env-3816761-clone481944-cljf.cdn.jelastic.net/Content/GeneralEntry/006551f6-03fc-4ba2-89da-e4e0dfaca763.webp"
                            [attr.data-lightbox]="'Gulmohar-Jaipur'">
                            <img
                                src="https://env-3816761-clone481944-cljf.cdn.jelastic.net/Content/GeneralEntry/006551f6-03fc-4ba2-89da-e4e0dfaca763.webp">
                        </a>
                    </div>
                </td>
                <td>
                    <div class="table-list-img d-flex align-items-center justify-content-center">
                        <a href="https://env-3816761-clone481944-cljf.cdn.jelastic.net/Content/GeneralEntry/006551f6-03fc-4ba2-89da-e4e0dfaca763.webp"
                            [attr.data-lightbox]="'Gulmohar-Jaipur'">
                            <img
                                src="https://env-3816761-clone481944-cljf.cdn.jelastic.net/Content/GeneralEntry/006551f6-03fc-4ba2-89da-e4e0dfaca763.webp">
                        </a>
                    </div>
                </td>
                <td> <a href="javascript:void(0)" class="text-dark">
                        https://gulmoharjaipur.com/all-product?prc=1,4299&page=1&pagesize=40 </a> </td>
                <td>
                    <a href="javascript:void(0)" title="Update Active Status">
                        <i class="fas fa-ban text-danger"></i>
                    </a>
                </td>
                <td class="table-action-btn">
                    <a href="javascript:void(0)" class="table-action-btn-edit" data-title="Edit"><i
                            class="fa-light fa-pen"></i>
                    </a>
                    <a class="table-action-btn-delete" data-title="Delete">
                        <i class="fa-light fa-trash-can"></i>
                    </a>
                </td>

            </tr>
            <tr>
                <td>2</td>
                <td>
                    <div class="table-list-img d-flex align-items-center justify-content-center">
                        <a href="https://env-3816761-clone481944-cljf.cdn.jelastic.net/Content/GeneralEntry/e2e5b6c2-d70d-4895-abba-c7631b355895.webp"
                            [attr.data-lightbox]="'Gulmohar-Jaipur'">
                            <img
                                src="https://env-3816761-clone481944-cljf.cdn.jelastic.net/Content/GeneralEntry/e2e5b6c2-d70d-4895-abba-c7631b355895.webp">
                        </a>
                    </div>
                </td>
                <td>
                    <div class="table-list-img d-flex align-items-center justify-content-center">
                        <a href="https://env-3816761-clone481944-cljf.cdn.jelastic.net/Content/GeneralEntry/e2e5b6c2-d70d-4895-abba-c7631b355895.webp"
                            [attr.data-lightbox]="'Gulmohar-Jaipur'">
                            <img
                                src="https://env-3816761-clone481944-cljf.cdn.jelastic.net/Content/GeneralEntry/e2e5b6c2-d70d-4895-abba-c7631b355895.webp">
                        </a>
                    </div>
                </td>
                <td> <a href="javascript:void(0)" class="text-dark">
                        https://gulmoharjaipur.com/all-product?prc=1,4299&page=1&pagesize=40 </a> </td>
                <td>
                    <a href="javascript:void(0)" title="Update Active Status">
                        <i class="fas fa-ban text-danger"></i>
                    </a>
                </td>
                <td class="table-action-btn">
                    <a href="javascript:void(0)" class="table-action-btn-edit" data-title="Edit"><i
                            class="fa-light fa-pen"></i>
                    </a>
                    <a class="table-action-btn-delete" data-title="Delete">
                        <i class="fa-light fa-trash-can"></i>
                    </a>
                </td>

            </tr>
            <tr>
                <td>1</td>
                <td>
                    <div class="table-list-img d-flex align-items-center justify-content-center">
                        <a href="https://env-3816761-clone481944-cljf.cdn.jelastic.net/Content/GeneralEntry/d28c18ec-3a92-4b6d-9b28-b75de6979ad1.webp"
                            [attr.data-lightbox]="'Gulmohar-Jaipur'">
                            <img
                                src="https://env-3816761-clone481944-cljf.cdn.jelastic.net/Content/GeneralEntry/d28c18ec-3a92-4b6d-9b28-b75de6979ad1.webp">
                        </a>
                    </div>
                </td>
                <td>
                    <div class="table-list-img d-flex align-items-center justify-content-center">
                        <a href="https://env-3816761-clone481944-cljf.cdn.jelastic.net/Content/GeneralEntry/d28c18ec-3a92-4b6d-9b28-b75de6979ad1.webp"
                            [attr.data-lightbox]="'Gulmohar-Jaipur'">
                            <img
                                src="https://env-3816761-clone481944-cljf.cdn.jelastic.net/Content/GeneralEntry/d28c18ec-3a92-4b6d-9b28-b75de6979ad1.webp">
                        </a>
                    </div>
                </td>
                <td> <a href="javascript:void(0)" class="text-dark">
                        https://gulmoharjaipur.com/all-product?prc=1,4299&page=1&pagesize=40 </a> </td>
                <td>
                    <a href="javascript:void(0)" title="Update Active Status">
                        <i class="fas fa-ban text-danger"></i>
                    </a>
                </td>
                <td class="table-action-btn">
                    <a href="javascript:void(0)" class="table-action-btn-edit" data-title="Edit"><i
                            class="fa-light fa-pen"></i>
                    </a>
                    <a class="table-action-btn-delete" data-title="Delete">
                        <i class="fa-light fa-trash-can"></i>
                    </a>
                </td>

            </tr>
        </tbody>
    </table>
</div>