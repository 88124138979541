<!-- <footer>
        <p class="m-0">Copyright © 2024 <a target="_blank" href="javascript:void(0);">Gulmohar Jaipur</a> All rights
                reserved, Powered by <a target="_blank" href="https://storeone.co.in/">StoreOne</a></p>
</footer> -->


<div class="mobile-footer-menu d-flex d-sm-none">
        <a href="javascript:void(0)" routerLinkActive="active" routerLink="/admin" [routerLinkActiveOptions]="{exact: true}"><i class="fa-light fa-gauge-high"></i>
                <span>Dashboard</span></a>
        <a href="javascript:void(0)" routerLinkActive="active" routerLink="./product/list" [routerLinkActiveOptions]="{exact: true}"><i class="fa-light fa-shirt"></i>
                <span>Product</span></a>
        <a href="javascript:void(0)" routerLinkActive="active" routerLink="./order/list" [routerLinkActiveOptions]="{exact: true}"><i class="fa-light fa-cart-shopping"></i>
                <span>Order</span></a>
        <a href="javascript:void(0)" routerLinkActive="active" routerLink="/admin/customer/list" [routerLinkActiveOptions]="{exact: true}"><i class="fa-light fa-users"></i>
                <span>Customers</span></a>
</div>