import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { BaseAPIService } from '../Helper/base-api.service';
import { ApiResponse, Dictionary } from '../Helper/common-model';
import { UserOrderModel } from './order.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  dashboardCount!: DashboardViewModel;
  constructor(private readonly _baseService: BaseAPIService) { }

  GetCountDetail(): Observable<ApiResponse<DashboardViewModel>> {
    let url = `${this._baseService.API_Url.Dashboard_GetCount_Api}`;

    return this._baseService.get(url).pipe(take(1));
  }
}
export interface DashboardViewModel {
  NewOrder?: number;
  CompletedOrder?: number;
  TotalCustomer?: number;
  TotalSales?: number;
  TotalOrder?: number;
}
