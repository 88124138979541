  <!-- Page Header Start-->
  <div class="page-new-header">
    <div class="page-new-header-title">
      Blog List
    </div>
    <div class="page-new-header-action">
      <button routerLink="" class="page-new-header-action-items" data-title="Add New Blog"> <i
          class="fa-regular fa-plus"></i></button>
      <div class="page-new-header-action-search">
        <button type="button" class="btn-filter-search"><i class="fa-light fa-magnifying-glass"></i></button>
        <input type="search" class="form-control" placeholder="Search..."/>
      </div>
    </div>
  </div>
  <!-- Page Header End-->