<!-- Page Header Start-->
<div class="page-new-header">
  <div class="page-new-header-title">
    Add Category
  </div>
  <div class="page-new-header-action">
    <button routerLink="" class="page-new-header-action-items" data-title="Back to list"> <i class="fa-light fa-arrow-left"></i> </button>
  </div>
</div>
<!-- Page Header End-->

<div class="content-box">
  <div class="row">

    <div class="col-md-1">
      <label>Sort order</label>
      <input type="text" class="form-control" id="input1" placeholder="01">
    </div>

    <div class="col-md-6">
      <label>Category name</label>
      <input type="text" class="form-control" id="input1" placeholder="Enter Name">
    </div>

    <div class="col-md-5">
      <label>Upload image </label>
      <div class="dropzone dropzone-light upload-btn-wrapper w-100">
        <button type="button" class="btn-upload-border d-block w-100 text-left"><i class="fa-regular fa-plus"></i>
          Upload</button>
        <input type="file" multiple="true" style="display: block;" />
        <div class="dz-default dz-message m-1">
          <span><i class="ti-cloud-up"></i></span>
        </div>
      </div>
    </div>


  </div>
  <div class="form-footer-btn-grp widget-content widget-content text-center mt-3">
    <button type="submit" class="btn btn-default me-3"> <i class="fas fa-check me-1"></i> Add Category</button>
    <button type="button" class="btn btn-default btn-border"> <i class="fas fa-times me-1"></i> Cancel</button>
  </div>
</div>
