<!-- Page Header Start-->
<div class="page-new-header">
    <div class="page-new-header-title">
        Seller Dashboard
        <!-- <nav class="custom-breadcrum">
      <ol class="breadcrumb justify-content-center justify-content-sm-start">
        <li class="breadcrumb-item"><a href="javascript:void()">Home</a></li>
        <li class="breadcrumb-item"><a routerLink="../" href="javascript:void()">CMS Page</a></li>
        <li class="breadcrumb-item active" aria-current="page">{{pageName | titlecase}}</li>
      </ol>
    </nav> -->
    </div>
    <div class="page-new-header-action">
        <div class="page-new-header-action-mini-filter-form">
            <div class="mini-filter-form-items form-select-arrow">
                <select class="form-control">
                    <option>All</option>
                    <option>B2C</option>
                    <option>B2B</option>
                </select>
            </div>
        </div>
    </div>
</div>
<!-- Page Header End-->

<div class="page-dashboard">
    <div class="row dashboard-cards">
        <div class="col-sm-6 col-lg-3 col-xl-3 mb-3">
            <a href="javascript:void(0)" routerLink="/admin/customer"
                class="dashboard-card-items d-flex align-items-start">
                <div class="dashboard-card-items-icon"> <i class="fa-light fa-users"></i> </div>
                <div class="dashboard-card-items-info">
                    <h1 class="mb-1">{{this.model!.TotalCustomer ?? 'N/A'}}</h1>
                    <p class="m-0">Customers</p>
                </div>
            </a>
        </div>
        <div class="col-sm-6 col-lg-3 col-xl-3 mb-3">
            <a href="javascript:void(0)" routerLink="/admin/order/list"
                class="dashboard-card-items d-flex align-items-start">
                <div class="dashboard-card-items-icon"> <i class="fa-light fa-cart-circle-arrow-down fa-shake"
                        style="--fa-animation-duration: 2s;"></i> </div>
                <div class="dashboard-card-items-info">
                    <h1 class="mb-1">{{this.model!.NewOrder ?? 'N/A'}}</h1>
                    <p class="m-0">New Orders</p>
                </div>
            </a>
        </div>
        <!-- <div class="col-sm-6 col-lg-3 col-xl-3 mb-3">
            <a href="javascript:void(0)" class="dashboard-card-items d-flex align-items-start">
                <div class="dashboard-card-items-icon bg-warning"> <i class="fa-light fa-cart-shopping-fast"></i> </div>
                <div class="dashboard-card-items-info">
                    <h1 class="mb-1">{{this.model!.CompletedOrder ?? 'N/A'}}</h1>
                    <p class="m-0">Total Complete Orders</p>
                </div>
            </a>
        </div> -->

        <div class="col-sm-6 col-lg-3 col-xl-3 mb-3">
            <a href="javascript:void(0)" routerLink="/admin/order/list"
                class="dashboard-card-items d-flex align-items-start">
                <div class="dashboard-card-items-icon"> <i class="fa-light fa-cart-shopping-fast"></i> </div>
                <div class="dashboard-card-items-info">
                    <h1 class="mb-1">{{this.model!.TotalOrder ?? 'N/A'}}</h1>
                    <p class="m-0">Total Orders</p>
                </div>
            </a>
        </div>
        <div class="col-sm-6 col-lg-3 col-xl-3 mb-3">
            <a href="javascript:void(0)" class="dashboard-card-items d-flex align-items-start">
                <div class="dashboard-card-items-icon"> <i class="fa-light fa-money-bill-trend-up"></i> </div>
                <div class="dashboard-card-items-info">
                    <h1 class="mb-1"><span>₹</span>{{this.model!.TotalSales ?? 'N/A'}}</h1>
                    <p class="m-0">Total Sales</p>
                </div>
            </a>
        </div>
    </div>
</div>